import React from 'react'
import { Container } from 'react-bootstrap'

export default function Sponsor2024() {
  return (
    <div className='padding-sec'>
      <Container>
        <div className="padding-sec text-center">
            <h2 className='title'>Content Coming Soon...</h2>
        </div>
      </Container>
    </div>
  )
}
